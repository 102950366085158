import http from '../../../../../../libs/http'
import calidad from '../../../../../../config/calidad-pharmasan'
export default class CarpetasServices {
  getCodigosGrupo (params) {
    return http.get(`${calidad.host}/codigos/listar`, {
      params
    })
  }

  getCodigos (params, codigo) {
    return http.get(`${calidad.host}/codigos/listar/${codigo}`, {
      params
    })
  }

  crearCodigo (data) {
    return http.post(`${calidad.host}/codigos`, data, {
      headers: {
        loading: true
      }
    })
  }
}
